import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { ProtectedRoute } from '../components/auth/ProtectedRoute';

const Login = lazy(() => import('../pages/Login'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Prompts = lazy(() => import('../pages/Prompts'));
const Workflows = lazy(() => import('../pages/Workflows'));

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/',
    element: <ProtectedRoute><Dashboard /></ProtectedRoute>
  },
  {
    path: '/prompts',
    element: <ProtectedRoute><Prompts /></ProtectedRoute>
  },
  {
    path: '/workflows',
    element: <ProtectedRoute><Workflows /></ProtectedRoute>
  }
];