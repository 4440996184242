import {
  Dashboard,
  Chat,
  Work
} from '@mui/icons-material';

export const DRAWER_WIDTH = 240;

export const MENU_ITEMS = [
  { text: 'Dashboard', icon: Dashboard, path: '/' },
  { text: 'Prompts', icon: Chat, path: '/prompts' },
  { text: 'Workflows', icon: Work, path: '/workflows' }
  
] as const; 